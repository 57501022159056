import * as React from "react"
import { Link } from "gatsby"

// styles
const pageStyles = {
  color: "white",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  width: '100vw',
  height: '100vh',
  backgroundImage: 'url(https://wordpress-api.kinlochgolfclub.com/wp-content/uploads/2021/03/Kinloch_Gate-Webpage_Image-small.png)',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#12284C',
  paddingTop: '20rem'
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
}
const paragraphStyles = {
  marginBottom: 48,
}
const buttonStyles = {
  border: '1px solid white',
  borderRadius: '8px',
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>404 - Page Not Found</title>
      <div className="text-center p-5" style={{ backgroundColor: 'rgba( 18, 40, 76, 0.6 )' }}>
        <h1 style={headingStyles}>Page Not Found</h1>
        <p style={paragraphStyles}>
          <Link to="/" className="text-white mt-5 d-inline-block py-2 px-3" style={buttonStyles}>GO BACK TO HOME</Link> 
        </p>
      </div>
    </main>
  )
}

export default NotFoundPage
